
import { defineComponent } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
import * as Yup from 'yup';
import ApiService from '@/core/services/ApiService';
import { boolean } from 'yup/lib/locale';
import TrainerVue from '@/layout/Trainer.vue';
import { VueCookieNext } from 'vue-cookie-next';

export default defineComponent({
  name: 'new-entity-course',
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      showCourseTerm: false,
      tranche: [],
      association: [],
      courseTermCount: 0,
      contactList: [],
      courseList: [],
      categoryList: [],
      sectorList: [],
      milestoneList: [],
      aliasList: [],
      skillLevelList: [],
      course: {
        entity_id: '',
        tranche: '',
        entity_tranche_contract_id: '',
        course_type: '',
        course_category: '',
        course_sector: '',
        unit_cost_total: '',
        x_course_name_id: '',
        code: '',
        course_name: '',
        total_target_batches: '',
        trainee_per_batch: '',
        min_day_per_month: '',
        total_course_cost: 0,
        total_target_trainee: '',
        min_age: '',
        max_age: '',
        age_grace_in_month: '',
        course_objective: '',
        entry_qualification: '',
        month: '',
        hour: '',
        day: '',
        session: '',
        term: '',

        isStipendCourse: 0,
        isManagementCourse: 0,
        isEmploymentEligible: 0,
        isReassessmentEnabled: 0,
        isTotCourse: 0,
        certification_authority: '',
        skill_level_id: '' as any,
      },
      configuration: {
        e_grace: '',
        // replacement_allowed: '',
        dropout_below_perc: '',
        dropout_rep_perc_per_batch: '',
        assessment_applicable: 0,
        certification_applicable: 0,

        emp_bill_claim_perc: '',
      },
      // billConfiguration: {
      //   x_training_milestone_id: "",
      //   milestone_param: "",
      //   claim_percentage: "",
      //   adj_adv_reduction_perc: "",
      // },
      terms: {
        no: [],
        month: [],
        day: [],
        session: [],
        hour: [],
        class_type: [],
        week: [],
      },

      bills: {
        x_claim_milestone_id: [] as any,
        x_training_milestone_id: [] as any,
        // milestone_param: [],
        claim_percentage: [] as any,
        adj_adv_reduction_perc: [],
      },
      bill_data: [] as any,
      term_data: [] as any,
      // term_day: [],
      // term_session: [],
      // term_hour: [],
      // class_type: [],
      // week_session: [],
      entityTypes: [],
      industry: [],

      loading: false,
      percentageInfo: '',
      courseTypeData: [] as any,
    };
  },
  async created() {
    await this.getAssociation();
    await this.getTranche();
    await this.getCourseTypeList();
    await this.getSkillLevelList();
    await this.getCategoryTypeList();
    await this.getSectorList();
    await this.getMilestoneList();
    await this.getAliasList();
  },
  methods: {
    isStipendCourseCheck(event) {
      if (event.target.checked) {
        this.course.isStipendCourse = 1;
        console.log(this.course.isStipendCourse);
      } else {
        this.course.isStipendCourse = 0;
        console.log(this.course.isStipendCourse);
      }
    },
    isManagementCourseCheck(event) {
      if (event.target.checked) {
        this.course.isManagementCourse = 1;
        console.log('management course' + this.course.isManagementCourse);
      } else {
        this.course.isManagementCourse = 0;
        console.log('management course' + this.course.isManagementCourse);
      }
    },
    isEmploymentCourseCheck(event) {
      if (event.target.checked) {
        this.course.isEmploymentEligible = 1;
        console.log('isEmploymentEligible' + this.course.isEmploymentEligible);
      } else {
        this.course.isEmploymentEligible = 0;
        console.log('isEmploymentEligible' + this.course.isEmploymentEligible);
      }
    },
    isReassessmentCheck(event) {
      if (event.target.checked) {
        this.course.isReassessmentEnabled = 1;
        console.log(
          'isReassessmentEnabled' + this.course.isReassessmentEnabled
        );
      } else {
        this.course.isReassessmentEnabled = 0;
        console.log(
          'isReassessmentEnabled' + this.course.isReassessmentEnabled
        );
      }
    },
    isToTCheck(event) {
      if (event.target.checked) {
        this.course.isTotCourse = 1;
        console.log('isTotCourse' + this.course.isTotCourse);
      } else {
        this.course.isTotCourse = 0;
        console.log('isTotCourse' + this.course.isTotCourse);
      }
    },
    isAssesmentCheck(event) {
      if (event.target.checked) {
        this.configuration.assessment_applicable = 1;
      } else {
        this.configuration.assessment_applicable = 0;
        console.log(
          'assessment_applicable' + this.configuration.assessment_applicable
        );
      }
    },
    isCertificationCheck(event) {
      if (event.target.checked) {
        this.configuration.certification_applicable = 1;
        console.log(
          'certification_applicable' +
            this.configuration.certification_applicable
        );
      } else {
        this.configuration.certification_applicable = 0;
        console.log(
          'certification_applicable' +
            this.configuration.certification_applicable
        );
      }
    },

    totalcourseCost() {
      if (this.course.total_target_trainee && this.course.unit_cost_total) {
        let targetTrainee = +this.course.total_target_trainee;
        let unitcost = +this.course.unit_cost_total;
        this.course.total_course_cost = targetTrainee * unitcost;
      }
    },
    courseTerm() {
      this.showCourseTerm = true;
      this.courseTermCount = +this.course.term;
    },
    async getContractPercentage(id) {
      await ApiService.get('entity/contract/show/' + id)
        .then((response) => {
          console.log(response.data.data);
          this.percentageInfo = response.data.data.contribution_percentage;
          this.course.min_age = response.data.data.min_age;
          this.course.max_age = response.data.data.max_age;
          this.course.age_grace_in_month =
            response.data.data.age_grace_in_month;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getTranche() {
      await ApiService.get('configurations/tranche/list')
        .then((response) => {
          this.tranche = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    // association
    async getAssociation() {
      let entity_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      await ApiService.get('entity/list?entity_id=' + entity_id)
        .then((response) => {
          this.association = response.data.data;
          console.log(response);
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getContactList() {
      await ApiService.get(
        'entity/contract/list?tranche=' +
          this.course.tranche +
          '&entity_info_id=' +
          this.course.entity_id
      )
        .then((response) => {
          this.contactList = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getCourseTypeList() {
      await ApiService.get('configurations/course_type/list')
        .then((response) => {
          this.courseList = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getCategoryTypeList() {
      await ApiService.get('configurations/category_type/list')
        .then((response) => {
          this.categoryList = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getSectorList() {
      await ApiService.get('configurations/sector_type/list')
        .then((response) => {
          this.sectorList = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getSkillLevelList() {
      await ApiService.get('configurations/course_skill_level/list')
        .then((response) => {
          this.skillLevelList = response.data.data;
          console.log('skill level :');
          console.log(response.data.data);
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getMilestoneList() {
      await ApiService.get('configurations/milestone_type/list')
        .then((response) => {
          this.milestoneList = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getAliasList() {
      await ApiService.get('configurations/alias_name/list')
        .then((response) => {
          this.aliasList = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async billConfiguration(course_type_id) {
      await ApiService.get('configurations/course_type/show/' + course_type_id)
        .then((response) => {
          this.courseTypeData = response.data.data;

          this.bills.claim_percentage[1] =
            response.data.data?.percentage_enrollment;
          this.bills.claim_percentage[2] =
            response.data.data?.percentage_certification;
          this.bills.claim_percentage[3] =
            response.data.data?.percentage_employment;
          this.bills.x_training_milestone_id[1] = 1;
          this.bills.x_training_milestone_id[2] = 2;
          this.bills.x_training_milestone_id[3] = 3;

          console.log('customize bill array ' + JSON.stringify(this.bills));
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },

    async formSubmit() {
      // console.log(this.course);
      // console.log(this.configuration);
      let count = +this.course.term;
      for (let i = 1; i <= count; i++) {
        var arrayData = {
          no: this.terms.no[i],
          day: this.terms.day[i],
          hour: this.terms.hour[i],
          month: this.terms.month[i],
          session: this.terms.session[i],
          class_type: this.terms.class_type[i],
          week: this.terms.week[i],
        };
        this.term_data.push(arrayData);
      }
      for (let i = 1; i <= 3; i++) {
        var billInfoData = {
          x_claim_milestone_id: this.bills.x_claim_milestone_id[i],
          x_training_milestone_id: this.bills.x_training_milestone_id[i],
          // milestone_param: this.bills.milestone_param[i],
          claim_percentage: this.bills.claim_percentage[i],
          adj_adv_reduction_perc: this.bills.adj_adv_reduction_perc[i],
        };
        this.bill_data.push(billInfoData);
      }
      let formData = new FormData();

      for (var key in this.course) {
        formData.set(key, this.course[key]);
      }
      for (var key in this.configuration) {
        formData.set(key, this.configuration[key]);
      }

      formData.set('all_term', JSON.stringify(this.term_data));
      formData.set('bill_info', JSON.stringify(this.bill_data));
      let user_id = VueCookieNext.getCookie('_seip_user');
      let role_id = VueCookieNext.getCookie('_seip_role_id');
      formData.set('role_id', role_id);
      formData.set('user_id', user_id);
      this.loading = true;
      await ApiService.post('course/save', formData)
        .then((response) => {
          this.loading = false;
          if (response.data.status === 'success') {
            this.emitter.emit('course-updated', true);
            this.showCourseTerm = false;
            Swal.fire({
              title: 'Success!',
              text: response.data.data,
              icon: 'success',
              buttonsStyling: false,
              confirmButtonText: 'Ok',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            }).then(() => {
              this.$router.push('/course/course-list');
              this.course = {
                entity_id: '',
                tranche: '',
                entity_tranche_contract_id: '',
                course_type: '',
                course_category: '',
                course_sector: '',
                unit_cost_total: '',
                x_course_name_id: '',
                code: '',
                course_name: '',

                total_target_batches: '',
                trainee_per_batch: '',
                min_day_per_month: '',
                total_course_cost: 0,
                total_target_trainee: '',
                min_age: '',
                max_age: '',
                age_grace_in_month: '',
                course_objective: '',
                entry_qualification: '',
                month: '',
                hour: '',
                day: '',
                session: '',
                term: '',
                isStipendCourse: 0,
                isManagementCourse: 0,
                isEmploymentEligible: 0,
                isReassessmentEnabled: 0,
                isTotCourse: 0,
                certification_authority: '',
                skill_level_id: '' as any,
              };
              this.configuration = {
                e_grace: '',
                // replacement_allowed: '',
                dropout_below_perc: '',
                dropout_rep_perc_per_batch: '',
                assessment_applicable: 0,
                certification_applicable: 0,

                emp_bill_claim_perc: '',
              };
              this.percentageInfo = '';
              this.term_data = [];
              (this.bills = {
                x_claim_milestone_id: [],
                x_training_milestone_id: [],
                // milestone_param: [],
                claim_percentage: [],
                adj_adv_reduction_perc: [],
              }),
                (this.bill_data = []);
            });
          } else {
            // this.course.month = "";
            // this.course.hour = "";
            // this.course.day = "";
            // this.course.session = "";
            this.term_data = [];
            this.bill_data = [];
            this.terms.month = [];
            this.terms.hour = [];
            this.terms.day = [];
            this.terms.session = [];

            this.bills.x_claim_milestone_id = [];
            this.bills.x_training_milestone_id = [];
            this.bills.claim_percentage = [];
            this.bills.adj_adv_reduction_perc = [];
            Swal.fire({
              title: 'Submission Error',
              html: response.data.message,
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: 'Close',
              customClass: {
                confirmButton: 'btn btn-danger',
              },
            });
          }
        })
        .catch(({ response }) => {
          this.loading = false;
          Swal.fire({
            title: 'Unknown error',
            html: response.data.error,
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: 'Close',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          });
          console.log(response);
        });
    },

    async getEntityTypes() {
      await ApiService.get('entity/types')
        .then((response) => {
          this.entityTypes = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
  },
  setup() {
    const AssociationSchema = Yup.object().shape({
      email: Yup.string()
        .email('Must be valid email')
        .required()
        .label('Email'),
      // entity_id: Yup.string().required().label("Association"),
      // entity_tranche_contract_id: Yup.string().required().label("Contract"),
      // course_type: Yup.string().required().label("Course type"),
      // course_category: Yup.string().required().label("Course Category"),
      // course_sector: Yup.string().required().label("Course Sector"),
      // unit_cost_total: Yup.string().required().label("Unit Cost"),
      // x_course_name_id: Yup.string().required().label("Course Alias Name"),
      // code: Yup.string().required().label("Code"),
      // course_name: Yup.string().required().label("Course Name"),
      // short_name: Yup.string().required().label("Course Short Name"),
      // total_target_batches: Yup.string().required().label("Target Batch"),
      // trainee_per_batch: Yup.string().required().label("Batch Size"),
      // total_target_trainee: Yup.string().required().label("Total Target Trainee"),
      // grace: Yup.string().required().label("Grace"),
      // course_objective: Yup.string().required().label("Course Objective"),
      // entry_qualification: Yup.string().required().label("Entry Qualification"),
      // term: Yup.string().required().label("Term"),
      // month: Yup.string().required().label("Month"),
      // day: Yup.string().required().label("Day"),
      // session: Yup.string().required().label("Session"),
      // hour: Yup.string().required().label("Hour"),
      // class_type: Yup.string().required().label("Class Type"),
      // week: Yup.string().required().label("Week"),
      // e_grace: Yup.string().required().label("EnrollMent Grace"),
      // replacement_allowed: Yup.string().required().label("Replacement Allowed"),
      // x_claim_milestone_id: Yup.string().required().label("Training Milestone"),
      // milestone_param: Yup.string().required().label("Milestone Param"),
      // claim_percentage: Yup.string().required().label("Claim Percentage"),
      // adj_adv_reduction_perc: Yup.string()
      //   .required()
      //   .label("Advance Adjustment Percentage"),
    });
    return {
      AssociationSchema,
    };
  },
});
